import React from "react";
import PageTitle from "../components/page-title/PageTitle";
import Menu from "../components/menu/Menu";
import Footer from "../components/footer/Footer";
import "./contact.css";

export const Contacts = () => {
  return (
    <>
      <Menu className="v2"/>
      <PageTitle currentPage={"Контакти"} />
      <Footer />
    </>
  );
};

import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import ScrollToAnchor from "./utils/ScrollToAnchor";
import Landing from "./Pages/Landing";
import { Contacts } from "./Pages/Contacts";
import Services from "./Pages/Services";
function App() {
  return (
    <div className="App">
      <Router>
        <>
          <ScrollToAnchor />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/contact" element={<Contacts />} />
            <Route path="/services" element={<Services />} />
          </Routes>
        </>
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import "./pageTitle.css";
import { Link } from "react-router-dom";

const PageTitle = ({ currentPage }) => {
  return (
    <section className="top-position1 py-0">
      <div
        className="page-title-section cover-background dark-overlay"
        data-overlay-dark="7"
        data-background="img/bg/bg-01.jpg"
        style={{ backgroundImage: 'url("assets/images/bg-01.jpg")' }}
      >
        <div className="container">
          <div className="row align-items-end mt-n1-9">
            <div className="col-md-6 mt-2">
              <div className="page-title text-center text-md-start">
                <span className="font-weight-600 text-uppercase ms-2">
                  Преоткрий иновацията
                </span>
                <h1 className="mb-0 mt-2 text-white text-uppercase  display-5">
                  {currentPage.toUpperCase()}
                </h1>
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <div className="breadcrumb text-center justify-content-center justify-content-md-end mb-0">
                <ul className="list-unstyled mb-0">
                  <li className="d-inline-block">
                    <Link to={"/"}>Начало</Link>
                  </li>
                  <li className="d-inline-block">
                    <a href="#!">{currentPage}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;

import React from 'react';
import './our-team.css';

const OurTeam = () => {
    return (
        <div class="container bg-light py-5 our-team">
            <div className="row">
                <div className="col-12 col-lg-6 order-2 order-lg-1 image-column">
                    <div class="inner-column">
                        <div class="image">
                            <div class="dark-layer"></div>
                            <img decoding="async" src="assets/images/handsome-business-man-engineer-hard-hat-building.jpg" alt='our-team' className='img-fluid my-auto' />
                        </div>
                    </div>

                </div>
                <div className="col-12 col-lg-6 order-1 order-lg-2">
                    <div class="row justify-content-center g-0">
                        <div class="col-md-10 col-lg-10">
                            <h2 class="text-heading mb-4 ">Нашият екип</h2>
                            <p class="mb-5 text-center">
                                Нашият екип е съставен от професионалисти с богат опит и умения, които владеят различни езици- английски, немски, нидерландски, френски, турски и руски език.<br /> <br /> Ние разбираме колко важно е за чуждестранните инвеститори да комуникират свободно и без затруднения, затова гарантираме, че няма да има езикови бариери при работата с нас.<br /> <br /> Независимо дали сте инвеститор, който планира да влезе на българския пазар, или вече сте тук и се нуждаете от съдействие, ние можем да ви осигурим пълна подкрепа на вашия език.                            </p>
                        </div>
                    </div>
                    {/* <div class="row justify-content-center text-start">
                        <div class="col-12 col-md-6 py-4">
                            <img src='assets/images/engineer.png' alt='engineer' className='icon' />
                            <h5 class="card-title py-3">Нашата Мисия</h5>
                            <p class="card-text">

                                Да изграждаме здрави, устойчиви структури, които издържат изпитанието на времето, като същевременно поддържаме фокус върху безопасността, качеството и иновациите във всеки проект.
                                {/* To build strong, sustainable structures that stand the test of time, while maintaining a focus on safety, quality, and innovation in every project. 
                            </p>

                        </div>
                        <div class="col-12 col-md-6 py-4">

                            <img src='assets/images/vision.png' alt='vision' className='icon' />
                            <h5 class="card-title py-3">Our Vision</h5>
                            <p class="card-text">
                                To be the leading construction company known for transforming ideas into reality through superior craftsmanship and a dedication to excellence.
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default OurTeam;
import React, { useEffect } from "react";
import Menu from "../components/menu/Menu";
import PageTitle from "../components/page-title/PageTitle";
import Footer from "../components/footer/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { services } from "../data/services";

import "./services.css";
import ScrollToTop from "../utils/ScrollToTop";
const Services = () => {
  const { state } = useLocation();
  const currentService = services.find(
    (service) => service.title === state.service
  );
  console.log(currentService);
  const navigate = useNavigate();
  return (
    <>
      <Menu className="v2" />
      <PageTitle currentPage={"Услуги"} />

      <div class="container service-container">
        <div class="row mt-n2-6">
          <div class="col-lg-4 order-2 order-lg-1 mt-3">
            <div class="pe-lg-3 pe-xl-1-6">
              <div class="service-details-sidebar">
                <aside class="widget widget-nav-menu" data-aos="zoom-in">
                  <div class="section-title side-bar position-relative mb-2">
                    <h6>Нашите Услуги</h6>
                  </div>
                  <ul class="list-style3">
                    {services.map((service) => {
                      return (
                        <li
                          key={service.id}
                          className={
                            service.title === state.service ? "active" : ""
                          }
                        >
                          <a
                            href=""
                            onClick={(event) => {
                              event.preventDefault();
                              navigate("/services", {
                                state: { service: service.title },
                              });
                            }}
                          >
                            {service.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </aside>
                <aside class="widget widget-address wow fadeIn">
                  <div class="section-title side-bar position-relative mb-1-6">
                    <h6>КОНТАКТИ</h6>
                  </div>
                  <div class="d-flex border-bottom border-color-light-white pb-4 mb-4">
                    <div class="flex-shrink-0">
                      <i class="fa fa-envelope text-primary"></i>
                    </div>
                    <div class="flex-grow-1 ms-3 px-2">
                      <h6 class="mb-0 text-white">Поща</h6>
                      <p class="mb-0 text-light"><a href="mailto:stroy@htengineering.bg">stroy@htengineering.bg</a></p>
                    </div>
                  </div>
                  <div class="d-flex border-bottom border-color-light-white pb-4 mb-4">
                    <div class="flex-shrink-0">
                      <i class="fa fa-phone text-primary"></i>
                    </div>
                    <div class="flex-grow-1 ms-3 px-2">
                      <h6 class="mb-0 text-white">Телефонен Номер</h6>
                      <p class="mb-0 text-light"><a href="tel:00359899898029">(+359)899898029</a></p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <i
                        class="fa fa-map-marker text-primary"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3 px-2">
                      <h6 class="mb-0 text-white">Адрес</h6>
                      <p class="mb-0 text-light">гр.София, акад. Борис Стефанов 35</p>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
          <div class="col-lg-8 order-1 order-lg-2 mt-3 service-details">
            <div class="row mb-2 mb-md-3 wow fadeIn" data-aos="zoom-in">
              <div class="col-lg-12">
                <div class="service-image">
                  <img src={currentService.image} alt="..." />
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-12">
                <h3 class="h4 mb-sm-3">{currentService.title}</h3>
                <p class="w-lg-95">{currentService.content}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;

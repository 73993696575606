import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./contacts.css";
export const Contacts = () => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [formInput, setFormInput] = useState({
    to_email: "stroy@htengineering.bg",
    user_email: "",
    user_phone: "",
  });

  const handleInput = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  function handleResponse() {
    setIsSuccess(true);
    setTimeout(() => {
      setMessageSent(false);
      setIsSuccess(false);
      setFormInput((currentForm) => ({
        ...currentForm,
        user_email: "",
        user_phone: "",
      }));
    }, 5000);
  }

  function onError() {
    setIsError(true);
    setTimeout(() => {
      setMessageSent(false);
      setIsError(false);
    }, 5000);
  }

  const submitQuestionnaire = async () => {
    setMessageSent(true);
    console.log(form.current);
    emailjs
      .sendForm("service_3luu72l", "template_1ximgw8", form.current, {
        publicKey: "J2yJ--AHjFsUwLqmk",
      })
      .then(
        () => {
          handleResponse();
        },
        (error) => {
          onError();
        }
      )
      .catch((err) => {
        console.error(err);
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    // let validated = validateForm();
    // if (validated) {
    submitQuestionnaire();
    // }
  }

  return (
    <>
      {messageSent && isError && (
        <div className="sent-error mb-4">
          <p>
            Изникна грешка, моля свържете се с нас на посочение телефон в
            контакти!
          </p>
        </div>
      )}

      {messageSent && isSuccess && (
        <div className="sent-success mb-4">
          <p>Съобщението е пратено успешно!</p>
        </div>
      )}
      <div class="d-flex flex-column z-index-9 align-items-center align-items-sm-center">
        <h3 class="text-white text-start mb-3">
          Оставете контакти и ще се свържем с Вас
        </h3>
        <div class="col-10 ">
          <form class="footer-subscribe" ref={form} id="regForm">
            <div class="form-group my-2">
              <input
                type="text"
                class="form-control"
                id="user_phone"
                onChange={handleInput}
                data-block-type="FormChild"
                name="user_phone"
                placeholder="Телефон"
              />
            </div>
            <div class="form-group mt-4">
              <input
                type="email"
                class="form-control"
                id="user_email"
                name="user_email"
                placeholder="E-mail"
                onChange={handleInput}
                data-block-type="FormChild"
              />
              <input
                type="hidden"
                name="to_email"
                value="stroy@htengineering.bg"
                data-block-type="FormChild"
              />
            </div>
            <a
              onClick={(event) => handleSubmit(event)}
              class="btn-learn aos-init aos-animate"
              data-aos="fade-left"
            >
              <i className="fa fa-paper-plane text-white"></i>
              <span className="pr-5 ">Изпрати</span>
            </a>
          </form>
        </div>
      </div>
    </>
  );
};

import React from "react";
import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import { Contacts } from "../contacts/Contacts";
const Footer = () => {
  const navigate = useNavigate();
  const scrollToAnchor = (event, page, section) => {
    event.preventDefault();
    navigate(page, { state: { section } });
  };

  return (
    <>
      <section class="receive-contact md">
        <div class="container">
          <div className="row">
            <div class="col-lg-8 col-xl-6">
                <Contacts />
            </div>
          </div>
        </div>
      </section>

      <footer class="bg-dark position-relative">
        <div class="container">
          <div class="row mt-n2-9 position-relative z-index-9">
            <div class="col-sm-6 col-lg-4 col-xl-3 mt-3">
              <div class="footer-logo mb-2">
                <Link
                  to={"/"}
                  onClick={(event) => scrollToAnchor(event, "/", "home")}
                >
                  <img src="assets/images/logo.png" alt="Logo" />
                </Link>
              </div>
              <p class="text-light   text-start">
                ХТ Инженеринг е вашият универсален партньор в областта на
                инфраструктурата.
              </p>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-4 mt-3 ">
              <div
                class="p-2 p-sm-3 p-xl-3 links text-start"
                data-aos="fade-left"
              >
                <div class="section-title title mb-1-9 position-relative">
                  <h3 class="h5 text-white">Бързи връзки</h3>
                </div>
                <ul class="footer-list list-unstyled mb-0">
                  <li class="mb-3">
                    <Link
                      to={"/"}
                      onClick={(event) => scrollToAnchor(event, "/", "home")}
                    >
                      Начало
                    </Link>
                  </li>
                  <li class="mb-3">
                    <Link
                      to={"/"}
                      onClick={(event) =>
                        scrollToAnchor(event, "/", "about-us")
                      }
                    >
                      За нас
                    </Link>
                  </li>
                  <li class="mb-3">
                    <Link
                      to={"/"}
                      onClick={(event) =>
                        scrollToAnchor(event, "/", "services")
                      }
                    >
                      Услуги
                    </Link>
                  </li>
                  <li class="mb-3">
                    <Link to={"/contact"}>Контакти</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 mt-3">
              <div
                class="bg-white p-2 p-sm-3 p-xl-5 position-relative contact-info text-start"
                data-aos="fade-left"
              >
                <h3 class="mb-4 text-dark">
                  <span class="title-sm">КОНТАКТИ</span>
                </h3>
                <div class="d-flex mb-4">
                  <div class="flex-shrink-0">
                    <i
                      class="fa fa-map-marker text-primary"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-dark mb-0">
                     гр.София, акад. Борис Стефанов 35
                    </p>
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="flex-shrink-0">
                    <i class="fa fa-phone text-primary"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-dark mb-0"><a href="tel:00359899898029">(+359)899898029</a></p>
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="flex-shrink-0">
                    <i class="fa fa-envelope text-primary"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-dark mb-0"><a href="mailto:stroy@htengineering.bg">stroy@htengineering.bg</a></p>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-shrink-0">
                    <i class="fa fa-calendar text-primary"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-dark mb-0">
                      Пон - Пет:<span class="ps-1">09:30 - 18:00</span>
                    </p>
                  </div>
                </div>
                <hr className="mt-5" />
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bar borders-top border-color-light-white mt-3">
          <div class="container">
            <div class="row">
              <div class="text-center mt-3 mt-md-0 ">
                <p class="d-inline-block text-light mb-0 display-30 display-md-29">
                  © <span class="current-year">2024 </span>Powered by{" "}
                  <b>AS Tech</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React from "react";
import './process.css';

function Process() {
  return (
    <>
      <section className="py-0 overflow-visible process-image-container" id="process">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="position-relative process-image mb-n10 video-img wow fadeIn"
                data-wow-delay="200ms"
                style={{visibility: "visible", animationDelay: "200ms", animationName: "fadeIn"}}
              >
                <span>Работен План</span>
                <img
                  src="assets/images/extra-02.jpg"
                  alt="..."
                />
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bg-dark cover-background process-steps-container"
        style={{ backgroundImage: 'url("assets/images/bg-06.png")' }}
      >
        <div className="container">
          <div className="row mt-n1-9">
            <div className="col-sm-6 col-lg-3 mt-1-9">
              <div
                className="text-center px-sm-4 wow fadeIn"
                data-wow-delay="200ms"
                style={{
                  visibility: "visible",
                  animationDelay: "200ms",
                  animationName: "fadeIn",
                }}
              >
                <div className="process-wrapper">
                  <div className="process-count">
                    <span>01</span>
                  </div>
                  <div className="process-icon">
                    <img src="assets/images/icon-01.png" alt="..." />
                  </div>
                </div>
                <h3 className="h5 text-white mb-0">Оферта</h3>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mt-1-9">
              <div
                className="text-center px-sm-4 mt-sm-2-9 wow fadeIn"
                data-wow-delay="400ms"
                style={{
                  visibility: "visible",
                  animationDelay: "400ms",
                  animationName: "fadeIn",
                }}
              >
                <div className="process-wrapper">
                  <div className="process-count">
                    <span>02</span>
                  </div>
                  <div className="process-icon">
                    <img src="assets/images/icon-02.png" alt="..." />
                  </div>
                </div>
                <h3 className="h5 text-white mb-0">Проектиране</h3>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mt-1-9">
              <div
                className="text-center px-sm-4 wow fadeIn"
                data-wow-delay="600ms"
                style={{
                  visibility: "visible",
                  animationDelay: "600ms",
                  animationName: "fadeIn",
                }}
              >
                <div className="process-wrapper">
                  <div className="process-count">
                    <span>03</span>
                  </div>
                  <div className="process-icon">
                    <img src="assets/images/icon-03.png" alt="..." />
                  </div>
                </div>
                <h3 className="h5 text-white mb-0">Изграждане</h3>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mt-1-9">
              <div
                className="text-center px-sm-4 mt-sm-2-9 wow fadeIn"
                data-wow-delay="800ms"
                style={{
                  visibility: "visible",
                  animationDelay: "800ms",
                  animationName: "fadeIn",
                }}
              >
                <div className="process-wrapper">
                  <div className="process-count">
                    <span>04</span>
                  </div>
                  <div className="process-icon">
                    <img src="assets/images/icon-04.png" alt="..." />
                  </div>
                </div>
                <h3 className="h5 text-white mb-0">Стартиране</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Process;

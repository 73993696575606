import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./menu.css";

function Menu({ className }) {
  const navigate = useNavigate();
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 200 ? setStickyClass("navbar-shrink") : setStickyClass("");
    }
  };

  const scrollToAnchor = (event, page, section) => {
    event.preventDefault();
    navigate(page, { state: { section } });
  };

  return (
    <Navbar
      scrolling
      collapseOnSelect
      expand="lg"
      id="mainNav"
      className={["fixed-top", stickyClass, className]}
    >
      <Container fluid="xxl">
        <Navbar.Brand as={Link} to={"/"}>
          <div id="logo">
            <img src="assets/images/logo.png" alt="Logo" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle className="x" aria-controls="responsive-navbar-nav">
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link
              as={Link}
              to={"/"}
              onClick={(event) => scrollToAnchor(event, "/", "home")}
            >
              Начало
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={(event) => scrollToAnchor(event, "/", "services")}
            >
              Услуги
            </Nav.Link>
            {/* <NavDropdown title="Услуги" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">
                  Строително изграждане
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.1">
                  Архитектурно проектиране
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.1">
                  Интериорно проектиране
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.1">
                  Общински Поръчки
                </NavDropdown.Item>
              </NavDropdown> */}
            <Nav.Link
              as={Link}
              onClick={(event) => scrollToAnchor(event, "/", "about-us")}
            >
              За нас
            </Nav.Link>

            {/* <Nav.Link as={Link} to={"/faq"}>
                ЧЗВ
              </Nav.Link>

              <Nav.Link
                onClick={(event) => scrollToAnchor(event, "/", "price-list")}
              >
                Ценоразспис
              </Nav.Link> */}
            <Nav.Link as={Link} to={"/contact"}>
              Контакти
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;
